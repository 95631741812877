import React from "react"
// import { Link, graphql } from "gatsby"

const Consultation = ({ data, location }) => {
  console.log(data)
  return (
    <div location={location}>
      Consultation Page
    </div>
  )
}

export default Consultation
